import { affiliateRegistrationStatuses } from '~/utils/helpers/affiliate-registration-helper'

export default function ({ $auth, redirect, route }) {
  if ($auth.loggedIn) {
    if (route.path.includes('/account/affiliate')) {
      if (!$auth.user.affiliate) {
        if (
          $auth.user.affiliate_registration &&
          $auth.user.affiliate_registration.status !== affiliateRegistrationStatuses.approved
        ) {
          return redirect('/affiliate/create-affiliate')
        }

        return redirect('/affiliate')
      } else if ($auth.user.affiliate && $auth.user.affiliate.is_new) {
        if (route.path !== '/account/affiliate') {
          return redirect('/account/affiliate')
        }
      }
    }
  }
}
