import UserService from '@/services/User'
import ProductService from '@/services/Product'

export const state = () => ({
  loading: false,
  product: null,

  // Wishlist
  loadingToggleWishlist: false,
  isProductWishlisted: false,
  totalProductWishlisted: 0,

  // Affiliate
  loadingAddProductAffiliate: false,
  isProductAffiliated: false
})

export const getters = {
  //
}

export const mutations = {
  setProduct (state, product) {
    state.product = product
  },

  // Wishlist
  setLoadingToggleWishlist (state, value) {
    state.loadingToggleWishlist = value
  },

  setIsProductWishlisted (state, value) {
    state.isProductWishlisted = value
  },

  setTotalProductWishlisted (state, value) {
    state.totalProductWishlisted = value
  },

  toggleAfterBtnWishlistClicked (state, isWishlisted) {
    if (isWishlisted) {
      state.totalProductWishlisted += 1
      state.isProductWishlisted = true
    } else {
      state.totalProductWishlisted -= 1
      state.isProductWishlisted = false
    }
  },

  // Affiliate
  setLoadingAddProductAffiliate (state, value) {
    state.loadingAddProductAffiliate = value
  },

  setIsProductAffiliated (state, value) {
    state.isProductAffiliated = value
  }
}

export const actions = {
  toggleProductToWishlist ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      UserService.toggleWishlist(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('toggleAfterBtnWishlistClicked', !state.isProductWishlisted)
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  addProductAffiliate ({ state }, payload) {
    return new Promise((resolve, reject) => {
      ProductService.addProductAffiliate(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  }
}
