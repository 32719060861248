export const appEnv = {
  production: 'production',
  staging: 'staging',
  development: 'development',
  local: 'local'
}

export const getPageNumber = (page) => {
  if (page) {
    return Number(page.substring(page.lastIndexOf('=') + 1, page.length))
  }
  return null
}

export const filterObjectKeys = (obj, excluded) => {
  const filteredKeys = Object.keys(obj).filter(key => !excluded.includes(key))
  const filteredObject = {}
  filteredKeys.forEach((key) => {
    filteredObject[key] = obj[key]
  })
  return filteredObject
}

export const changeToBase64 = (blob, headers) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      const base64data = reader.result

      let originalFilename = ''
      if (headers) {
        const contentDisposition = headers['content-disposition'] || null

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]+)"?/)
          if (match) {
            originalFilename = match[1]
          }
        }
      }

      resolve({ base64data, originalFilename })
    }

    reader.readAsDataURL(blob)
  })
}
