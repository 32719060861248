import BrandServices from '@/services/Brand'
import { getPageNumber } from '@/utils/helper'
export const state = () => ({
  productBrands: [],
  productBrandParts: [],
  productBrandPromotions: [],
  brands: [],
  brand: {},
  productBrand: {},
  productNavbar: [],
  onLoaded: false,
  affiliateUserBrand: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  },
  bestSellingBrandAffiliate: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false
  }
})

export const mutations = {
  SET_PRODUCT_BRANDS (state, payload) {
    state.productBrands = payload
  },
  SET_PRODUCT_BRAND_PARTS (state, payload) {
    state.productBrandParts = payload
  },
  SET_PRODUCT_BRAND_PROMOTIONS (state, payload) {
    state.productBrandPromotions = payload
  },
  SET_PRODUCT_BRAND (state, payload) {
    state.productBrand = payload
  },
  SET_PRODUCT_NAVBAR (state, payload) {
    state.productNavbar = payload
  },
  SET_BRANDS (state, payload) {
    state.brands = payload
  },
  SET_BRAND (state, payload) {
    state.brand = payload
  },
  SET_LOADING (state, payload) {
    state.onLoaded = payload
  },
  setAffiliateUserBrand (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.affiliateUserBrand.data.length) {
        state.affiliateUserBrand.data = data
      } else {
        const oldData = state.affiliateUserBrand.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.affiliateUserBrand.data = oldData.concat(data)
      }
    } else {
      state.affiliateUserBrand.data = data
    }

    state.affiliateUserBrand.pagination = { totalPage, total, nextPage }
    state.affiliateUserBrand.paginated = paginated
  },
  setBestSellingBrandAffiliate (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.bestSellingBrandAffiliate.data.length) {
        state.bestSellingBrandAffiliate.data = data
      } else {
        const oldData = state.bestSellingBrandAffiliate.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.bestSellingBrandAffiliate.data = oldData.concat(data)
      }
    } else {
      state.bestSellingBrandAffiliate.data = data
    }

    state.bestSellingBrandAffiliate.pagination = { totalPage, total, nextPage }
    state.bestSellingBrandAffiliate.paginated = paginated
  },
  resetBestSellingBrandAffiliate (state, payload) {
    state.bestSellingBrandAffiliate = {
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false,
      loading: false
    }
  }
}

export const actions = {
  getProductBrands ({ commit }, slug, payload) {
    return new Promise((resolve, reject) => {
      BrandServices.getProductBrands(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        slug,
        payload
      )
        .then((response) => {
          if (response.data.message === 'success') {
            commit('SET_PRODUCT_BRANDS', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getProductBrandNavbar ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BrandServices.getProductBrandNavbar(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.data.message === 'success') {
            commit('SET_PRODUCT_NAVBAR', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getListBrands ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BrandServices.getListBrands(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('SET_BRANDS', response.data.data.brands)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  showBrand ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BrandServices.showBrand(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('SET_BRAND', response.data.data.brand)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getListBrandsSale ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BrandServices.getListBrandsSale(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.type,
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('SET_BRANDS', response.data.data.brands)
            commit('SET_LOADING', true)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAffiliateTopBrands ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BrandServices.getAffiliateTopBrands(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const brands = response.data.data.brands
          commit('setAffiliateUserBrand', {
            paginated: payload.params.paginated,
            data: brands.data,
            totalPage: brands.last_page,
            total: brands.total,
            nextPage: getPageNumber(brands.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getBestSellingBrandAffiliate ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      BrandServices.getBestSellingBrandAffiliate(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const brands = response.data.data.brands
          commit('setBestSellingBrandAffiliate', {
            paginated: payload.params.paginated,
            data: brands.data,
            totalPage: brands.last_page,
            total: brands.total,
            nextPage: getPageNumber(brands.next_page_url)
          })
          resolve(response)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  }
}

export const getters = {}
