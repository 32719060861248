export const sortArrayByKey = (array, key) => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })
}

export const isArrayNotNullOrEmpty = (array) => {
  return !!(array && array.length)
}
