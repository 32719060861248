import { sortArrayByKey } from '@/utils/helpers/array-helper'

export const fileTypes = {
  image: 'image',
  video: 'video',
  audio: 'audio',
  document: 'document'
}

export const fileVariants = {
  thumbnail: 'thumbnail',
  original: 'original'
}

export const defaultImages = {
  defaultProductImage: '/img/default-image/default_product_image.png',
  defaultStoreLogo: '/img/default-image/default_store_logo.png'
}

export const getDefaultImageFromFiles = (files = [], name = null, variant = null, webp = false) => {
  if (files.length <= 0) { return '' }

  files = sortArrayByKey(
    files.filter(file => file.type === fileTypes.image),
    'sequence'
  )

  if (name) {
    files = files.filter(file => file.name === name)
  }

  if (variant) {
    files = files.filter(file => file.variant === variant)
  }

  if (files.length <= 0) { return '' }

  return webp ? files[0].value + '.webp' : files[0].value
}

export const getDefaultImgProductOnError = (event) => {
  if (event.target.src && event.target.src.includes('.webp')) {
    event.target.src = event.target.src.replace('.webp', '')
  } else {
    event.target.src = defaultImages.defaultProductImage
  }
}
