import Pusher from 'pusher-js'

export default ({ app }, inject) => {
  if (!app.$auth.loggedIn) {
    return
  }

  inject('pusher',
    new Pusher(process.env.PUSHER_KEY, {
      cluster: process.env.PUSHER_CLUSTER,
      forceTLS: true,
      authEndpoint: `${process.env.BASE_API}/v1/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: app.$auth.getToken('local')
        }
      }
    })
  )
}
