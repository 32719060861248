export const notifTypes = {
    payment: {
        text: 'Pembayaran'
    },
    'order-packaging': {
        text: 'Pesanan Dikemas'
    },
    'order-shipping': {
        text: 'Pengiriman'
    },
    'order-done': {
        text: ['Order Sampai', 'Order Selesai', 'Order Dibatalkan']
    },
    discount: {
        text: ['Diskon', 'Sale']
    },
    'event-sale': {
        text: 'Event Sale'
    },
    voucher: {
        text: ['Voucher', 'Poin']
    },
    affiliate: {
        text: 'Afiliasi'
    }
}

export const getIconNotif = (name) => {
    switch (name) {
        case notifTypes.payment.text :
            return 'payment'
        case notifTypes['order-packaging'].text:
            return 'order-packaging'
        case notifTypes['order-shipping'].text:
            return 'order-shipping'
        case notifTypes['order-done'].text[0]:
            return 'order-done'
        case notifTypes['order-done'].text[1]:
            return 'order-done'
        case notifTypes['order-done'].text[2]:
            return 'order-done'
        case notifTypes.discount.text[0]:
            return 'discount'
        case notifTypes.discount.text[1]:
            return 'discount'
        case notifTypes['event-sale'].text:
            return 'event-sale'
        case notifTypes.voucher.text[0]:
            return 'voucher'
        case notifTypes.voucher.text[1]:
            return 'voucher'
        case notifTypes.affiliate.text:
            return 'affiliate'
    }
}
