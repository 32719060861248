import { apiClient, getHeaders } from '@/services/axiosConfig'
export default {
  getUserAddress (auth, payload) {
    return apiClient({
      method: 'post',
      url: '/v1/general/addresses',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  getUserDetailAddress (auth, payload) {
    return apiClient({
      method: 'get',
      url: `/v1/general/addresses/${payload}`,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      }
    })
  },
  addUserAddress (auth, payload) {
    return apiClient({
      method: 'put',
      url: '/v1/general/addresses',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  editUserAddress (auth, id, payload) {
    return apiClient({
      method: 'patch',
      url: '/v1/general/addresses/' + id,
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  },
  deleteUserAddress (auth, payload) {
    return apiClient({
      method: 'delete',
      url: '/v1/general/addresses',
      headers: {
        ...apiClient.defaults.headers,
        ...getHeaders(auth.loggedIn, auth.value)
      },
      ...payload
    })
  }
}
