import OrderReviewService from '@/services/Order'
export const state = () => ({
  order: {},
  orderID: null,
  allOrder: [],
  orderNotPaid: [],
  orderProcess: [],
  orderSent: [],
  orderDone: [],
  orderCanceled: [],
  orderReadyPickUp: [],
  orderReviews: {},
  orderDetailPayment: {},
  orderTracking: [],
  suggestions: [],
  productOrder: {},
  onSuccess: false
})

export const mutations = {
  setOrderReviews (state, payload) {
    const { data } = payload
    if (!state.orderReviews.data || !state.orderReviews.data.length) {
      state.orderReviews = payload
    } else {
      const oldData = state.orderReviews.data
      state.orderReviews = {
        ...payload,
        data: oldData.concat(...data)
      }
    }
  },
  setAllOrders (state, payload) {
    if (payload.current_page === 1) {
      state.allOrder = payload.data
    } else {
      payload.data.map((pay) => {
        state.allOrder.push(pay)
      })
    }
  },
  setOrderNotPaid (state, payload) {
    if (payload.current_page === 1) {
      state.orderNotPaid = payload.data
    } else {
      payload.data.map((pay) => {
        state.allOrder.push(pay)
      })
    }
  },
  setOrderProcess (state, payload) {
    if (payload.current_page === 1) {
      state.orderProcess = payload.data
    } else {
      payload.data.map((pay) => {
        state.orderProcess.push(pay)
      })
    }
  },
  setOrderSent (state, payload) {
    if (payload.current_page === 1) {
      state.orderSent = payload.data
    } else {
      payload.data.map((pay) => {
        state.orderSent.push(pay)
      })
    }
  },
  setOrderDone (state, payload) {
    if (payload.current_page === 1) {
      state.orderDone = payload.data
    } else {
      payload.data.map((pay) => {
        state.orderDone.push(pay)
      })
    }
  },
  setOrderCanceled (state, payload) {
    if (payload.current_page === 1) {
      state.orderCanceled = payload.data
    } else {
      payload.data.map((pay) => {
        state.orderCanceled.push(pay)
      })
    }
  },
  setOrderReadyPickUp (state, payload) {
    if (payload.current_page === 1) {
      state.orderReadyPickUp = payload.data
    } else {
      payload.data.map((pay) => {
        state.orderReadyPickUp.push(pay)
      })
    }
  },
  setDetailOrder (state, payload) {
    state.order = payload
  },
  setDetailOrderPayment (state, payload) {
    state.orderDetailPayment = payload
  },
  setTrackingOrder (state, payload) {
    state.orderTracking = payload
  },
  setOnSuccess (state, payload) {
    state.onSuccess = true
    state.order = payload
  },
  setReviewSuggestions (state, payload) {
    state.suggestions = payload
  },
  setProductOrder (state, payload) {
    state.productOrder = payload
  },
  resetTracking (state) {
    state.orderTracking = []
  },
  resetOrder (state, payload) {
    state[payload] = []
  },
  setOrder (state, payload) {
    state.order = payload
  },
  setOrderID (state, payload) {
    state.orderID = payload
  }
}

export const actions = {
  getMyOrderReviews ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.getMyOrderReviews(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const commitPayload = {
            ...response.data.data.reviews
          }
          commit('setOrderReviews', commitPayload)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getMyOrders ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.getMyOrders(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.payload
      )
        .then((response) => {
          if (response.status === 200) {
            if (payload.type === 'orderNotPaid') {
              commit('setOrderNotPaid', response.data.data.orders)
            } else if (payload.type === 'orderProcess') {
              commit('setOrderProcess', response.data.data.orders)
            } else if (payload.type === 'orderSent') {
              commit('setOrderSent', response.data.data.orders)
            } else if (payload.type === 'orderDone') {
              commit('setOrderDone', response.data.data.orders)
            } else if (payload.type === 'orderCanceled') {
              commit('setOrderCanceled', response.data.data.orders)
            } else if (payload.type === 'orderReadyPickUp') {
              commit('setOrderReadyPickUp', response.data.data.orders)
            } else {
              commit('setAllOrders', response.data.data.orders)
            }
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getDetailOrder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.getDetailOrder(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setDetailOrder', response.data.data.order)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  addOrder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.addOrder(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            commit('setOnSuccess', response.data.data)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  addReview ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.addReview(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getOrderPaymentDetail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.getOrderPaymentDetail(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setDetailOrderPayment', response.data.data.order_payment)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getTrackingOrder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.getTrackingOrder(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setTrackingOrder', response.data.data.rajaongkir)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getReviewSuggestions ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.getReviewSuggestions(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit(
              'setReviewSuggestions',
              response.data.data.review_suggestions
            )
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getOrderProductDetail ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.getOrderProductDetail(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            commit('setProductOrder', response.data.data.order_product)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  editStatus ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.editStatus(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  postPaymentConfirmation ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.postPaymentConfirmation(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  downloadInvoice ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.downloadInvoice(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  cancelOrder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.cancelOrder(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload.id,
        payload.payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  checkReorder ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      OrderReviewService.checkReorder(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  }
}
