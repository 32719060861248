import CartServices from '@/services/Cart'
import { getPageNumber } from '@/utils/helper'
export const state = () => ({
  carts: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false,
    cartSummary: { productCount: 0, storeCount: 0 }
  },
  cart: [],
  cartNavbar: {},
  isBuyNow: false, // type : cart & buynow
  cartEmpty: {
    data: [],
    pagination: { totalPage: 0, total: 0 },
    paginated: true,
    loadMore: false,
    loading: false,
    cartSummary: { productCount: 0, storeCount: 0 }
  },
  cartItemId: [],
  deleteItem: {},
  sourceDelete: 'available', // 'available' | 'unavailable'
  selectItem: [],
  selectedCartGroups: [],
  checkoutItem: [],
  isLoading: true,
  withCart: true,
  selectedProduct: {},
  selectedVariant: '',
  store: '',
  qty: 1,
  cartAvailability: {}
})

export const mutations = {
  setLoadingGeneral (state, { path, value, loadMore }) {
    const keys = path.split('.')
    let obj = state;

    // eslint-disable-next-line no-unused-vars
    for (const key of keys) {
      obj = obj[key]
      if (!obj) { return }
    }

    if (loadMore) {
      obj.loadMore = !obj.loadMore
    }

    obj.loading = value;
  },
  setCarts (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.carts.data.length) {
        state.carts.data = data
      } else {
        const oldData = state.carts.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.carts.data = oldData.concat(data)
      }
    } else {
      state.carts.data = data
    }

    state.carts.pagination = { totalPage, total, nextPage }
    state.carts.paginated = paginated
  },
  setCartSummary (state, payload) {
    const { source, value } = payload
    state[source].cartSummary = {
      productCount: value.product_count,
      storeCount: value.store_count
    }
  },
  setCart (state, payload) {
    state.cart = payload
  },
  setCartNavbar (state, payload) {
    state.cartNavbar = payload
  },
  setIsBuyNow (state, payload) {
    state.isBuyNow = payload
  },
  setEmptyCart (state, payload) {
    const { paginated, data, totalPage, total, nextPage } = payload

    if (paginated) {
      if (!state.cartEmpty.data.length) {
        state.cartEmpty.data = data
      } else {
        const oldData = state.cartEmpty.data
        oldData.forEach((oldDt) => {
          const dataIdx = data.findIndex((dt) => {
            return oldDt.id === dt.id
          })

          if (dataIdx > -1) {
            data.splice(dataIdx, 1)
          }
        })

        state.cartEmpty.data = oldData.concat(data)
      }
    } else {
      state.cartEmpty.data = data
    }

    state.cartEmpty.pagination = { totalPage, total, nextPage }
    state.cartEmpty.paginated = paginated
  },
  setCartItemId (state, payload) {
    state.cartItemId = payload
  },
  setCartItemQuantity (state, { id, currentVal, type }) {
    const tempCart = state.carts.data
    const cartIdx = tempCart.findIndex((cart) => {
      return cart.id === id
    })

    if (cartIdx > -1) {
      const changeCart = tempCart[cartIdx]
      changeCart.quantity = currentVal
      state.carts.data.splice(cartIdx, 1, changeCart)
    }
  },
  setDeleteItem (state, payload) {
    state.deleteItem = payload
  },
  setSourceDelete (state, payload) {
    state.sourceDelete = payload
  },
  setRemoveDeletedCartProduct (state, payload) {
    const { ids: deletedIds, all } = payload
    const source = {
      available: 'carts',
      unavailable: 'cartEmpty'
    }
    if (all) {
      state.carts = {
        data: [],
        pagination: { totalPage: 0, total: 0 },
        paginated: true,
        loadMore: false,
        loading: false
      }
    } else {
      let successDeleted = 0
      deletedIds.forEach((id) => {
        const deletedIdx = state[source[state.sourceDelete]].data.findIndex((dt) => {
          return dt.id === id
        })

        if (deletedIdx > -1) {
          state[source[state.sourceDelete]].data.splice(deletedIdx, 1)
          successDeleted += 1
        }
      })

      state[source[state.sourceDelete]].pagination.total = state[source[state.sourceDelete]].pagination.total - successDeleted
    }
  },
  setWishlistCartProduct (state, payload) {
    const { ids: wishlistIds, condition, source } = payload

    wishlistIds.forEach((id) => {
      const wishlistIdx = state[source].data.findIndex((dt) => {
        return dt.product_detail.product.id === id
      })

      if (wishlistIdx > -1) {
        state[source].data[wishlistIdx].is_wishlisted = !condition
      }
    })
  },
  resetCarts (state, payload) {
    state.carts = {
      data: [],
      pagination: { totalPage: 0, total: 0 },
      paginated: true,
      loadMore: false,
      loading: false,
      cartSummary: { productCount: 0, storeCount: 0 }
    }
  },
  setSelectedItem (state, payload) {
    let index = -1
    index = state.selectItem.findIndex((item, i) => {
      return item.cartId === payload.cartId
    })

    // else if (typeof payload === 'object') {
    //   payload.map((p) => {
    //     state.selectItem.push(p)
    //   })
    // }

    if (index > -1) {
      state.selectItem.splice(index, 1)
    } else {
      state.selectItem.push(payload)
    }
  },
  resetSelectedItem (state) {
    state.selectItem = []
  },
  setSelectedCartGroups (state, payload) {
    const { id, type } = payload // type = 'add' | 'delete'

    if (type === 'add') {
      const checkExistingGroup = state.selectedCartGroups.some((group) => {
        return group === id
      })

      if (!checkExistingGroup) {
        state.selectedCartGroups.push(id)
      }
    } else {
      const existingGroupIdx = state.selectedCartGroups.findIndex((group) => {
        return group === id
      })

      if (existingGroupIdx > -1) {
        state.selectedCartGroups.splice(existingGroupIdx, 1)
      }
    }
  },
  resetSelectedCartGroups (state, payload) {
    state.selectedCartGroups = []
  },
  setCheckoutItem (state, payload) {
    let index = -1
    state.checkoutItem.map((item, i) => {
      if (item.product_detail_id === payload.product_detail_id) {
        index = i
      }
    })
    if (index >= 0) {
      state.checkoutItem.splice(index, 1)
    } else {
      state.checkoutItem.push(payload)
    }
  },
  setSelectedProduct (state, payload) {
    state.selectedProduct = payload
  },
  setSelectedVariant (state, payload) {
    state.selectedVariant = payload
  },
  resetItem (state, _payload) {
    state.checkoutItem = []
    state.selectItem = []
  },
  setWithCart (state, payload) {
    state.withCart = payload
  },
  setSelectedStore (state, payload) {
    state.store = payload
  },
  setLoading (state, payload) {
    state.isLoading = payload
  },
  setQty (state, payload) {
    state.qty = payload
  },
  setCartAvailability (state, payload) {
    state.cartAvailability = payload
  }
}

export const actions = {
  getCarts ({ commit }, payload) {
    commit('setLoadingGeneral', { path: 'carts', value: true, loadMore: payload.params.onLoadMore })
    return new Promise((resolve, reject) => {
      CartServices.getCarts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const carts = response.data.data.carts
          commit('setCarts', {
            paginated: payload.params.paginated,
            data: payload.params.paginated ? carts.data : carts,
            totalPage: payload.params.paginated ? carts.last_page : 1,
            total: payload.params.paginated ? carts.total : carts.length,
            nextPage: payload.params.paginated ? getPageNumber(carts.next_page_url) : null
          })
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        }).finally(() => {
          commit('setLoadingGeneral', { path: 'carts', value: false, loadMore: payload.params.onLoadMore })
        })
    })
  },
  getCartSummary ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCartSummary(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      ).then((response) => {
        commit('setCartSummary', {
          source: 'carts',
          value: response.data.data.cart
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getEmptyCartSummary ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCartSummary(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      ).then((response) => {
        commit('setCartSummary', {
          source: 'cartEmpty',
          value: response.data.data.cart
        })
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getCartsNavbar ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCartsNavbar(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            const payload = {
              carts: response.data.data.carts,
              totalCart: response.data.data.total_cart
            }
            commit('setCartNavbar', payload)
          }
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getEmptyCarts ({ commit }, payload) {
    commit('setLoadingGeneral', { path: 'cartEmpty', value: true, loadMore: payload.params.onLoadMore })
    return new Promise((resolve, reject) => {
      CartServices.getCarts(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          const carts = response.data.data.carts
          commit('setEmptyCart', {
            paginated: payload.params.paginated,
            data: payload.params.paginated ? carts.data : carts,
            totalPage: payload.params.paginated ? carts.last_page : 1,
            total: payload.params.paginated ? carts.total : carts.length,
            nextPage: payload.params.paginated ? getPageNumber(carts.next_page_url) : null
          })
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          commit('setLoadingGeneral', { path: 'cartEmpty', value: false, loadMore: payload.params.onLoadMore })
        })
    })
  },
  addCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.addCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  addBulkCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.addBulkCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  updateCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.updateCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  deleteCart ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.deleteCart(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  getCartAvailability ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      CartServices.getCartAvailability(
        {
          loggedIn: this.$auth.loggedIn,
          value: this.$auth.loggedIn
            ? this.$auth.getToken('local')
            : this.$cookies.get('session_id') || ''
        },
        payload
      )
        .then((response) => {
          commit('setCartAvailability', response.data.data)
          resolve(response)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  selectedItem ({ commit }, payload) {
    commit('setSelectedItem', payload)
  },
  checkoutItem ({ commit }, payload) {
    commit('setCheckoutItem', payload)
  },
  actionWithCart ({ commit }, payload) {
    commit('setWithCart', payload)
  },
  handlingLoading ({ commit }, payload) {
    commit('setLoading', payload)
  }
}

export const getters = {
  getCheckDisabledGotoCheckout: (state) => {
    const checkFulfilled = state.selectItem.some(item => item.isFulfilled)
    const checkPreorder = state.selectItem.some(item => item.isPreorder)
    const checkFlashsale = state.selectItem.some(item => item.isFlashsale)
    const checkOrdinaryProduct = state.selectItem.some(item => !item.isFulfilled && !item.isPreorder && !item.isFlashsale)

    const arrCheck = [checkFulfilled, checkPreorder, checkFlashsale]

    if (checkPreorder && checkOrdinaryProduct) {
      return true
    }

    const checkTrueState = arrCheck.filter(check => check === true)
    return checkTrueState.length > 1
  },
  getTotalProductCart: (state) => {
    let total = 0
    if (!state.cart.length) {
      return 0
    }
    state.cart.map((c) => {
      total += c.cart_items.length
    })
    return total
  },
  getTotalProductCartEmpty: (state) => {
    let total = 0
    if (!state.cartEmpty.length) {
      return 0
    }
    state.cartEmpty.map((c) => {
      total += c.cart_items.length
    })
    return total
  },
  getTotalPrice (state) {
    let total = 0

    if (state.selectItem.length) {
      // state.cart.map((c) => {
      //   if (c.cart_items) {
      //     c.cart_items.map((c2) => {
      //       state.selectItem.map((i) => {
      //         if (c2.id === i) {
      //           total += c2.product.discounted_price * c2.quantity
      //         }
      //       })
      //     })
      //   } else {
      //     state.selectItem.map((i) => {
      //       if (c.id === i) {
      //         total += c.product.discounted_price * c.quantity
      //       }
      //     })
      //   }
      // })
      state.selectItem.forEach((item) => {
        const dtIndex = state.carts.data.findIndex((cart) => {
          return cart.id === item.cartId
        })

        if (dtIndex > -1) {
          total += state.carts.data[dtIndex].quantity * Number(state.carts.data[dtIndex].product_detail.discounted_price)
        }
      })
    }

    return total
  },
  getTotalSelectedItem (state) {
    // let total = 0
    let qty = 0
    if (state.selectItem) {
      // total += state.selectItem.length
      state.selectItem.forEach((item) => {
        const dtIndex = state.carts.data.findIndex((cart) => {
          return cart.id === item.cartId
        })

        if (dtIndex > -1) {
          qty += state.carts.data[dtIndex].quantity
        }
      })
    }
    return qty
  },
  getCartProductListId (state) {
    const onCartProductId = []
    state.cart.map((c) => {
      c.cart_items.map((c2) => {
        onCartProductId.push(c2.product.id)
      })
    })
    state.cartEmpty.map((c3) => {
      c3.cart_items.map((c4) => {
        onCartProductId.push(c4.product.id)
      })
    })
    return onCartProductId
  },
  getTotalPayment (state) {
    let total = 0
    state.cart.map((c) => {
      total += c.total_price - c.total_discount
    })
    return total
  },
  // navbar
  getListCart (state) {
    let carts = []

    if (state.cartNavbar.carts && state.cartNavbar.carts.length) {
      carts = [
        ...state.cartNavbar.carts
      ]
    }

    return carts
  },
  getTotalQtyCart (state) {
    let totalQty = 0

    if (state.cartNavbar.totalCart) {
      totalQty = state.cartNavbar.totalCart
      if (totalQty > 99) {
        return '99+'
      } else {
        return totalQty.toString()
      }
    }

    return totalQty.toString()
  },
  getTotalPriceCartNavbar (state) {
    let price = 0

    if (state.cartNavbar.carts) {
      const carts = state.cartNavbar.carts
      carts.map((c) => {
        price += Number(c.discounted_price) * c.quantity
      })
    }
    return price
  },
  haveCart (state) {
    return state.carts.data.length || state.cartEmpty.data.length
  },
  isEventSale (state) {
    let valid = 0
    if (state.selectItem.length) {
      state.cart.map((c) => {
        c.cart_items.map((item) => {
          const index = state.selectItem.findIndex((select) => {
            return select === item.id
          })
          if (item.product.event_ongoing.event_sale.length && index >= 0) {
            valid++
          }
        })
      })
    }
    return valid === state.selectItem.length
  },
  getPaymentDetail (state) {
    if (state.cartAvailability && Object.keys(state.cartAvailability).length) {
      return state.cartAvailability.payment_detail
    }
    return {}
  }
}
